import React, {useEffect, useState} from "react";
import './App.css';
import axios from "axios";
import {Button, Col, Input, Layout, message, Row, Table, Tag} from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const {Search} = Input;


axios.defaults.baseURL = "https://api.aws.dp-cargo.com/";

const config = {
    headers: {
        "Content-Type": "application/json",
    },
};


function App() {


    const [listTracking, setListTracking] = useState([]);
    // eslint-disable-next-line
    const [listTrackingStock, setListTrackingStock] = useState([]);
    // const [dateSearch, setDateSearch] = useState(null);
    // const dateFormat = 'YYYY-MM-DD';

    useEffect(() => {
        axios
            .get("/tracking/sub", config)
            .then((response) => {
                let rs = response.data.result
                //setListTracking(rs)
                setListTrackingStock(rs)
            })
            .catch((error) => error.message);
    }, []);


    const columns = [
        {
            title: <div style={{textAlign: "center"}}>Mã ĐH</div>,
            key: "id",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.id}</div>;
            },
            sorter: (a, b) => a.id - b.id,
            width: '10%',
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: <div style={{textAlign: "center"}}>Code Tracking</div>,
            key: "codeTracking",
            width: '10%',
            render(o) {
                return <div style={{textAlign: "center"}}>
                    <CopyToClipboard text={o.codeTracking}>
                        <Button onClick={() => {
                            message.success("Đã copy code tracking")
                        }}>
                            {o.codeTracking}
                        </Button>
                    </CopyToClipboard>
                </div>
            },

        },
        {
            title: <div style={{textAlign: "center"}}>Ngày kho nhận</div>,
            key: "dateWarehouseUsReceived",
            width: '20%',
            render(o) {
                return <div style={{textAlign: "center"}}>{o.dateWarehouseUsReceived}</div>;
            },


        },
        {
            title: <div style={{textAlign: "center"}}>Khách hàng</div>,
            key: "hasCustomer",
            width: '10%',
            render(o) {

                if (o.hasCustomer)
                    return <div style={{textAlign: "center"}}>
                        <Tag style={{padding: "5px"}} color="#f98404">Đã có khách nhận</Tag>
                    </div>
                return <div style={{textAlign: "center"}}>
                    <Tag style={{padding: "5px"}} color="#3d84b8">Chưa có khách nhận</Tag>
                </div>

            },

        },
        {
            title: "",
            key: "no",
            visible: false,
            className: "hide",

            render() {

                return null

            },

        },
    ]

    const dataSource = listTracking.map(o => {
        return {
            key: o.id,
            id: o.id,
            codeTracking: o.codeTracking,
            dateWarehouseUsReceived: o.dateWarehouseUsReceived,
            hasCustomer: o.hasCustomer,
        }
    })


    async function onChangeCodeSearch(value) {
        console.log(value.trim())
        if (value === "") {
            setListTracking(listTrackingStock)
            return
        }
        let newData = [...listTrackingStock]
        newData = newData.filter(tracking => {
            return tracking.codeTracking.includes(value)

        })


        if (newData.length === 0) {
            message.error("Không có đơn nào cả")
        }
        setListTracking(newData)
    }

    //
    // function onChangeDate(date) {
    //
    //     if (date !== null) {
    //         setDateSearch(date)
    //     }
    //
    // }


    /*    async function onClickSearch() {
            if (dateSearch !== null) {
                let startDate = dateSearch[0].add(-1, 'd').format(dateFormat)
                let endDate = dateSearch[1].add(1, 'd').format(dateFormat)
                let newData = [...listTrackingStock]
                newData = newData.filter(tracking => {
                    let date = moment(tracking.dateWarehouseUsReceived, "DD/MM/YYYY HH:mm:ss").format(dateFormat)
                    return moment(date).isBetween(startDate, endDate)

                })
                if (newData.length === 0) {
                    message.error("Không có đơn nào cả")
                }
                setListTracking(newData)
            }
        }*/

    return (
        <Layout className="layout" style={{background: "#f0f2f5", minHeight: "100vh"}}>
            <div style={{background: "#fff", margin: "20px", minHeight: "100vh"}}>
                <h3 style={{fontSize: "25px", padding: "30px"}}>Hệ thống theo dõi tracking DP-Cargo</h3>
                <Row>
                    <Col span={10} offset={1} style={{marginBottom: "20px"}}>
                        <h3>Tìm theo code tracking</h3>
                        <Search placeholder="" onSearch={onChangeCodeSearch}/>
                    </Col>
                    <Col offset={1}>
                        {/*  <h3>Tìm theo ngày</h3>
                        <RangePicker format={dateFormat} onChange={onChangeDate}
                                     placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}/>
                        <Button onClick={onClickSearch} style={{marginLeft: "10px"}}>Tìm kiếm</Button>*/}
                    </Col>
                </Row>
                <Row>
                    <Col offset={1}>
                        <Button onClick={() => setListTracking([])}>Xoá tìm kiếm</Button>
                    </Col>
                </Row>
                <Table
                    style={{padding: "0 300px ", with: "auto"}}
                    size="small"
                    bordered
                    columns={columns} dataSource={dataSource}
                    pagination={{
                        defaultPageSize: 50,
                        showSizeChanger: true,
                        pageSizeOptions: ["50", "100", "200", "300", "400"],
                    }}
                />
            </div>
        </Layout>
    );
}

export default App;
